























import {
    Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class Search extends Vue {
    @Prop({
        type: Number,
        default: 500,
    })
    searchDelay!: number;

    // TODO: Replace by event (eg. on-typing-end)
    @Prop({
        type: Function,
        default: null,
    })
    afterType!: (query: string) => void | null;

    @Prop({
        type: String,
        default: null,
    })
    inputRef!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    hideSearchIcon!: boolean;

    @Prop({
        type: String,
        required: false,
    })
    defaultValue?: string;

    typeTimeout: ReturnType<typeof setTimeout> | null = null;

    @Prop({
        type: String,
        default: 'Search',
    })
    placeholder?: string;

    checkDebounce(e: { target: HTMLInputElement }) {
        const query = e.target.value;

        this.$emit('input', query);

        if (this.typeTimeout) {
            clearTimeout(this.typeTimeout);
        }

        if (this.afterType) {
            this.typeTimeout = setTimeout(() => {
                if (query) {
                    this.afterType(query.trim());
                    return;
                }
                this.afterType(query);
            }, this.searchDelay);
        }
    }

    handleBlur() {
        this.$emit('blur');
    }

    handleFocus() {
        this.$emit('focus');
    }
}

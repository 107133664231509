// https://gist.github.com/tiagodealmeida/0b97ccf117252d742dddf098bc6cc58a

export const COUNTRIES = {
    country: [
        {
            countryCode: 'BD',
            countryName: 'Bangladesh',
            currencyCode: 'BDT',
            population: '156118464',
            capital: 'Dhaka',
            continentName: 'Asia',
            countryCodeIso3: 'BGD',
        },
        {
            countryCode: 'BE',
            countryName: 'Belgium',
            currencyCode: 'EUR',
            population: '10403000',
            capital: 'Brussels',
            continentName: 'Europe',
            countryCodeIso3: 'BEL',
        },
        {
            countryCode: 'BF',
            countryName: 'Burkina Faso',
            currencyCode: 'XOF',
            population: '16241811',
            capital: 'Ouagadougou',
            continentName: 'Africa',
            countryCodeIso3: 'BFA',
        },
        {
            countryCode: 'BG',
            countryName: 'Bulgaria',
            currencyCode: 'BGN',
            population: '7148785',
            capital: 'Sofia',
            continentName: 'Europe',
            countryCodeIso3: 'BGR',
        },
        {
            countryCode: 'BA',
            countryName: 'Bosnia and Herzegovina',
            currencyCode: 'BAM',
            population: '4590000',
            capital: 'Sarajevo',
            continentName: 'Europe',
            countryCodeIso3: 'BIH',
        },
        {
            countryCode: 'BB',
            countryName: 'Barbados',
            currencyCode: 'BBD',
            population: '285653',
            capital: 'Bridgetown',
            continentName: 'North America',
            countryCodeIso3: 'BRB',
        },
        {
            countryCode: 'WF',
            countryName: 'Wallis and Futuna',
            currencyCode: 'XPF',
            population: '16025',
            capital: 'Mata-Utu',
            continentName: 'Oceania',
            countryCodeIso3: 'WLF',
        },
        {
            countryCode: 'BL',
            countryName: 'Saint Barthélemy',
            currencyCode: 'EUR',
            population: '8450',
            capital: 'Gustavia',
            continentName: 'North America',
            countryCodeIso3: 'BLM',
        },
        {
            countryCode: 'BM',
            countryName: 'Bermuda',
            currencyCode: 'BMD',
            population: '65365',
            capital: 'Hamilton',
            continentName: 'North America',
            countryCodeIso3: 'BMU',
        },
        {
            countryCode: 'BN',
            countryName: 'Brunei',
            currencyCode: 'BND',
            population: '395027',
            capital: 'Bandar Seri Begawan',
            continentName: 'Asia',
            countryCodeIso3: 'BRN',
        },
        {
            countryCode: 'BO',
            countryName: 'Bolivia',
            currencyCode: 'BOB',
            population: '9947418',
            capital: 'Sucre',
            continentName: 'South America',
            countryCodeIso3: 'BOL',
        },
        {
            countryCode: 'BH',
            countryName: 'Bahrain',
            currencyCode: 'BHD',
            population: '738004',
            capital: 'Manama',
            continentName: 'Asia',
            countryCodeIso3: 'BHR',
        },
        {
            countryCode: 'BI',
            countryName: 'Burundi',
            currencyCode: 'BIF',
            population: '9863117',
            capital: 'Bujumbura',
            continentName: 'Africa',
            countryCodeIso3: 'BDI',
        },
        {
            countryCode: 'BJ',
            countryName: 'Benin',
            currencyCode: 'XOF',
            population: '9056010',
            capital: 'Porto-Novo',
            continentName: 'Africa',
            countryCodeIso3: 'BEN',
        },
        {
            countryCode: 'BT',
            countryName: 'Bhutan',
            currencyCode: 'BTN',
            population: '699847',
            capital: 'Thimphu',
            continentName: 'Asia',
            countryCodeIso3: 'BTN',
        },
        {
            countryCode: 'JM',
            countryName: 'Jamaica',
            currencyCode: 'JMD',
            population: '2847232',
            capital: 'Kingston',
            continentName: 'North America',
            countryCodeIso3: 'JAM',
        },
        {
            countryCode: 'BV',
            countryName: 'Bouvet Island',
            currencyCode: 'NOK',
            population: '0',
            capital: '',
            continentName: 'Antarctica',
            countryCodeIso3: 'BVT',
        },
        {
            countryCode: 'BW',
            countryName: 'Botswana',
            currencyCode: 'BWP',
            population: '2029307',
            capital: 'Gaborone',
            continentName: 'Africa',
            countryCodeIso3: 'BWA',
        },
        {
            countryCode: 'WS',
            countryName: 'Samoa',
            currencyCode: 'WST',
            population: '192001',
            capital: 'Apia',
            continentName: 'Oceania',
            countryCodeIso3: 'WSM',
        },
        {
            countryCode: 'BQ',
            countryName: 'Bonaire',
            currencyCode: 'USD',
            population: '18012',
            capital: 'Kralendijk',
            continentName: 'North America',
            countryCodeIso3: 'BES',
        },
        {
            countryCode: 'BR',
            countryName: 'Brazil',
            currencyCode: 'BRL',
            population: '201103330',
            capital: 'Brasília',
            continentName: 'South America',
            countryCodeIso3: 'BRA',
        },
        {
            countryCode: 'BS',
            countryName: 'Bahamas',
            currencyCode: 'BSD',
            population: '301790',
            capital: 'Nassau',
            continentName: 'North America',
            countryCodeIso3: 'BHS',
        },
        {
            countryCode: 'JE',
            countryName: 'Jersey',
            currencyCode: 'GBP',
            population: '90812',
            capital: 'Saint Helier',
            continentName: 'Europe',
            countryCodeIso3: 'JEY',
        },
        {
            countryCode: 'BY',
            countryName: 'Belarus',
            currencyCode: 'BYR',
            population: '9685000',
            capital: 'Minsk',
            continentName: 'Europe',
            countryCodeIso3: 'BLR',
        },
        {
            countryCode: 'BZ',
            countryName: 'Belize',
            currencyCode: 'BZD',
            population: '314522',
            capital: 'Belmopan',
            continentName: 'North America',
            countryCodeIso3: 'BLZ',
        },
        {
            countryCode: 'RU',
            countryName: 'Russia',
            currencyCode: 'RUB',
            population: '140702000',
            capital: 'Moscow',
            continentName: 'Europe',
            countryCodeIso3: 'RUS',
        },
        {
            countryCode: 'RW',
            countryName: 'Rwanda',
            currencyCode: 'RWF',
            population: '11055976',
            capital: 'Kigali',
            continentName: 'Africa',
            countryCodeIso3: 'RWA',
        },
        {
            countryCode: 'RS',
            countryName: 'Serbia',
            currencyCode: 'RSD',
            population: '7344847',
            capital: 'Belgrade',
            continentName: 'Europe',
            countryCodeIso3: 'SRB',
        },
        {
            countryCode: 'TL',
            countryName: 'East Timor',
            currencyCode: 'USD',
            population: '1154625',
            capital: 'Dili',
            continentName: 'Oceania',
            countryCodeIso3: 'TLS',
        },
        {
            countryCode: 'RE',
            countryName: 'Réunion',
            currencyCode: 'EUR',
            population: '776948',
            capital: 'Saint-Denis',
            continentName: 'Africa',
            countryCodeIso3: 'REU',
        },
        {
            countryCode: 'TM',
            countryName: 'Turkmenistan',
            currencyCode: 'TMT',
            population: '4940916',
            capital: 'Ashgabat',
            continentName: 'Asia',
            countryCodeIso3: 'TKM',
        },
        {
            countryCode: 'TJ',
            countryName: 'Tajikistan',
            currencyCode: 'TJS',
            population: '7487489',
            capital: 'Dushanbe',
            continentName: 'Asia',
            countryCodeIso3: 'TJK',
        },
        {
            countryCode: 'RO',
            countryName: 'Romania',
            currencyCode: 'RON',
            population: '21959278',
            capital: 'Bucharest',
            continentName: 'Europe',
            countryCodeIso3: 'ROU',
        },
        {
            countryCode: 'TK',
            countryName: 'Tokelau',
            currencyCode: 'NZD',
            population: '1466',
            capital: '',
            continentName: 'Oceania',
            countryCodeIso3: 'TKL',
        },
        {
            countryCode: 'GW',
            countryName: 'Guinea-Bissau',
            currencyCode: 'XOF',
            population: '1565126',
            capital: 'Bissau',
            continentName: 'Africa',
            countryCodeIso3: 'GNB',
        },
        {
            countryCode: 'GU',
            countryName: 'Guam',
            currencyCode: 'USD',
            population: '159358',
            capital: 'Hagåtña',
            continentName: 'Oceania',
            countryCodeIso3: 'GUM',
        },
        {
            countryCode: 'GT',
            countryName: 'Guatemala',
            currencyCode: 'GTQ',
            population: '13550440',
            capital: 'Guatemala City',
            continentName: 'North America',
            countryCodeIso3: 'GTM',
        },
        {
            countryCode: 'GS',
            countryName: 'South Georgia and the South Sandwich Islands',
            currencyCode: 'GBP',
            population: '30',
            capital: 'Grytviken',
            continentName: 'Antarctica',
            countryCodeIso3: 'SGS',
        },
        {
            countryCode: 'GR',
            countryName: 'Greece',
            currencyCode: 'EUR',
            population: '11000000',
            capital: 'Athens',
            continentName: 'Europe',
            countryCodeIso3: 'GRC',
        },
        {
            countryCode: 'GQ',
            countryName: 'Equatorial Guinea',
            currencyCode: 'XAF',
            population: '1014999',
            capital: 'Malabo',
            continentName: 'Africa',
            countryCodeIso3: 'GNQ',
        },
        {
            countryCode: 'GP',
            countryName: 'Guadeloupe',
            currencyCode: 'EUR',
            population: '443000',
            capital: 'Basse-Terre',
            continentName: 'North America',
            countryCodeIso3: 'GLP',
        },
        {
            countryCode: 'JP',
            countryName: 'Japan',
            currencyCode: 'JPY',
            population: '127288000',
            capital: 'Tokyo',
            continentName: 'Asia',
            countryCodeIso3: 'JPN',
        },
        {
            countryCode: 'GY',
            countryName: 'Guyana',
            currencyCode: 'GYD',
            population: '748486',
            capital: 'Georgetown',
            continentName: 'South America',
            countryCodeIso3: 'GUY',
        },
        {
            countryCode: 'GG',
            countryName: 'Guernsey',
            currencyCode: 'GBP',
            population: '65228',
            capital: 'St Peter Port',
            continentName: 'Europe',
            countryCodeIso3: 'GGY',
        },
        {
            countryCode: 'GF',
            countryName: 'French Guiana',
            currencyCode: 'EUR',
            population: '195506',
            capital: 'Cayenne',
            continentName: 'South America',
            countryCodeIso3: 'GUF',
        },
        {
            countryCode: 'GE',
            countryName: 'Georgia',
            currencyCode: 'GEL',
            population: '4630000',
            capital: 'Tbilisi',
            continentName: 'Asia',
            countryCodeIso3: 'GEO',
        },
        {
            countryCode: 'GD',
            countryName: 'Grenada',
            currencyCode: 'XCD',
            population: '107818',
            capital: 'St. George\'s',
            continentName: 'North America',
            countryCodeIso3: 'GRD',
        },
        {
            countryCode: 'GB',
            countryName: 'United Kingdom',
            currencyCode: 'GBP',
            population: '62348447',
            capital: 'London',
            continentName: 'Europe',
            countryCodeIso3: 'GBR',
        },
        {
            countryCode: 'GA',
            countryName: 'Gabon',
            currencyCode: 'XAF',
            population: '1545255',
            capital: 'Libreville',
            continentName: 'Africa',
            countryCodeIso3: 'GAB',
        },
        {
            countryCode: 'SV',
            countryName: 'El Salvador',
            currencyCode: 'USD',
            population: '6052064',
            capital: 'San Salvador',
            continentName: 'North America',
            countryCodeIso3: 'SLV',
        },
        {
            countryCode: 'GN',
            countryName: 'Guinea',
            currencyCode: 'GNF',
            population: '10324025',
            capital: 'Conakry',
            continentName: 'Africa',
            countryCodeIso3: 'GIN',
        },
        {
            countryCode: 'GM',
            countryName: 'Gambia',
            currencyCode: 'GMD',
            population: '1593256',
            capital: 'Bathurst',
            continentName: 'Africa',
            countryCodeIso3: 'GMB',
        },
        {
            countryCode: 'GL',
            countryName: 'Greenland',
            currencyCode: 'DKK',
            population: '56375',
            capital: 'Nuuk',
            continentName: 'North America',
            countryCodeIso3: 'GRL',
        },
        {
            countryCode: 'GI',
            countryName: 'Gibraltar',
            currencyCode: 'GIP',
            population: '27884',
            capital: 'Gibraltar',
            continentName: 'Europe',
            countryCodeIso3: 'GIB',
        },
        {
            countryCode: 'GH',
            countryName: 'Ghana',
            currencyCode: 'GHS',
            population: '24339838',
            capital: 'Accra',
            continentName: 'Africa',
            countryCodeIso3: 'GHA',
        },
        {
            countryCode: 'OM',
            countryName: 'Oman',
            currencyCode: 'OMR',
            population: '2967717',
            capital: 'Muscat',
            continentName: 'Asia',
            countryCodeIso3: 'OMN',
        },
        {
            countryCode: 'TN',
            countryName: 'Tunisia',
            currencyCode: 'TND',
            population: '10589025',
            capital: 'Tunis',
            continentName: 'Africa',
            countryCodeIso3: 'TUN',
        },
        {
            countryCode: 'JO',
            countryName: 'Jordan',
            currencyCode: 'JOD',
            population: '6407085',
            capital: 'Amman',
            continentName: 'Asia',
            countryCodeIso3: 'JOR',
        },
        {
            countryCode: 'HR',
            countryName: 'Croatia',
            currencyCode: 'HRK',
            population: '4284889',
            capital: 'Zagreb',
            continentName: 'Europe',
            countryCodeIso3: 'HRV',
        },
        {
            countryCode: 'HT',
            countryName: 'Haiti',
            currencyCode: 'HTG',
            population: '9648924',
            capital: 'Port-au-Prince',
            continentName: 'North America',
            countryCodeIso3: 'HTI',
        },
        {
            countryCode: 'HU',
            countryName: 'Hungary',
            currencyCode: 'HUF',
            population: '9982000',
            capital: 'Budapest',
            continentName: 'Europe',
            countryCodeIso3: 'HUN',
        },
        {
            countryCode: 'HK',
            countryName: 'Hong Kong',
            currencyCode: 'HKD',
            population: '6898686',
            capital: 'Hong Kong',
            continentName: 'Asia',
            countryCodeIso3: 'HKG',
        },
        {
            countryCode: 'HN',
            countryName: 'Honduras',
            currencyCode: 'HNL',
            population: '7989415',
            capital: 'Tegucigalpa',
            continentName: 'North America',
            countryCodeIso3: 'HND',
        },
        {
            countryCode: 'HM',
            countryName: 'Heard Island and McDonald Islands',
            currencyCode: 'AUD',
            population: '0',
            capital: '',
            continentName: 'Antarctica',
            countryCodeIso3: 'HMD',
        },
        {
            countryCode: 'VE',
            countryName: 'Venezuela',
            currencyCode: 'VEF',
            population: '27223228',
            capital: 'Caracas',
            continentName: 'South America',
            countryCodeIso3: 'VEN',
        },
        {
            countryCode: 'PR',
            countryName: 'Puerto Rico',
            currencyCode: 'USD',
            population: '3916632',
            capital: 'San Juan',
            continentName: 'North America',
            countryCodeIso3: 'PRI',
        },
        {
            countryCode: 'PS',
            countryName: 'Palestine',
            currencyCode: 'ILS',
            population: '3800000',
            capital: '',
            continentName: 'Asia',
            countryCodeIso3: 'PSE',
        },
        {
            countryCode: 'PW',
            countryName: 'Palau',
            currencyCode: 'USD',
            population: '19907',
            capital: 'Melekeok',
            continentName: 'Oceania',
            countryCodeIso3: 'PLW',
        },
        {
            countryCode: 'PT',
            countryName: 'Portugal',
            currencyCode: 'EUR',
            population: '10676000',
            capital: 'Lisbon',
            continentName: 'Europe',
            countryCodeIso3: 'PRT',
        },
        {
            countryCode: 'SJ',
            countryName: 'Svalbard and Jan Mayen',
            currencyCode: 'NOK',
            population: '2550',
            capital: 'Longyearbyen',
            continentName: 'Europe',
            countryCodeIso3: 'SJM',
        },
        {
            countryCode: 'PY',
            countryName: 'Paraguay',
            currencyCode: 'PYG',
            population: '6375830',
            capital: 'Asunción',
            continentName: 'South America',
            countryCodeIso3: 'PRY',
        },
        {
            countryCode: 'IQ',
            countryName: 'Iraq',
            currencyCode: 'IQD',
            population: '29671605',
            capital: 'Baghdad',
            continentName: 'Asia',
            countryCodeIso3: 'IRQ',
        },
        {
            countryCode: 'PA',
            countryName: 'Panama',
            currencyCode: 'PAB',
            population: '3410676',
            capital: 'Panama City',
            continentName: 'North America',
            countryCodeIso3: 'PAN',
        },
        {
            countryCode: 'PF',
            countryName: 'French Polynesia',
            currencyCode: 'XPF',
            population: '270485',
            capital: 'Papeete',
            continentName: 'Oceania',
            countryCodeIso3: 'PYF',
        },
        {
            countryCode: 'PG',
            countryName: 'Papua New Guinea',
            currencyCode: 'PGK',
            population: '6064515',
            capital: 'Port Moresby',
            continentName: 'Oceania',
            countryCodeIso3: 'PNG',
        },
        {
            countryCode: 'PE',
            countryName: 'Peru',
            currencyCode: 'PEN',
            population: '29907003',
            capital: 'Lima',
            continentName: 'South America',
            countryCodeIso3: 'PER',
        },
        {
            countryCode: 'PK',
            countryName: 'Pakistan',
            currencyCode: 'PKR',
            population: '184404791',
            capital: 'Islamabad',
            continentName: 'Asia',
            countryCodeIso3: 'PAK',
        },
        {
            countryCode: 'PH',
            countryName: 'Philippines',
            currencyCode: 'PHP',
            population: '99900177',
            capital: 'Manila',
            continentName: 'Asia',
            countryCodeIso3: 'PHL',
        },
        {
            countryCode: 'PN',
            countryName: 'Pitcairn Islands',
            currencyCode: 'NZD',
            population: '46',
            capital: 'Adamstown',
            continentName: 'Oceania',
            countryCodeIso3: 'PCN',
        },
        {
            countryCode: 'PL',
            countryName: 'Poland',
            currencyCode: 'PLN',
            population: '38500000',
            capital: 'Warsaw',
            continentName: 'Europe',
            countryCodeIso3: 'POL',
        },
        {
            countryCode: 'PM',
            countryName: 'Saint Pierre and Miquelon',
            currencyCode: 'EUR',
            population: '7012',
            capital: 'Saint-Pierre',
            continentName: 'North America',
            countryCodeIso3: 'SPM',
        },
        {
            countryCode: 'ZM',
            countryName: 'Zambia',
            currencyCode: 'ZMW',
            population: '13460305',
            capital: 'Lusaka',
            continentName: 'Africa',
            countryCodeIso3: 'ZMB',
        },
        {
            countryCode: 'EH',
            countryName: 'Western Sahara',
            currencyCode: 'MAD',
            population: '273008',
            capital: 'Laâyoune / El Aaiún',
            continentName: 'Africa',
            countryCodeIso3: 'ESH',
        },
        {
            countryCode: 'EE',
            countryName: 'Estonia',
            currencyCode: 'EUR',
            population: '1291170',
            capital: 'Tallinn',
            continentName: 'Europe',
            countryCodeIso3: 'EST',
        },
        {
            countryCode: 'EG',
            countryName: 'Egypt',
            currencyCode: 'EGP',
            population: '80471869',
            capital: 'Cairo',
            continentName: 'Africa',
            countryCodeIso3: 'EGY',
        },
        {
            countryCode: 'ZA',
            countryName: 'South Africa',
            currencyCode: 'ZAR',
            population: '49000000',
            capital: 'Pretoria',
            continentName: 'Africa',
            countryCodeIso3: 'ZAF',
        },
        {
            countryCode: 'EC',
            countryName: 'Ecuador',
            currencyCode: 'USD',
            population: '14790608',
            capital: 'Quito',
            continentName: 'South America',
            countryCodeIso3: 'ECU',
        },
        {
            countryCode: 'IT',
            countryName: 'Italy',
            currencyCode: 'EUR',
            population: '60340328',
            capital: 'Rome',
            continentName: 'Europe',
            countryCodeIso3: 'ITA',
        },
        {
            countryCode: 'VN',
            countryName: 'Vietnam',
            currencyCode: 'VND',
            population: '89571130',
            capital: 'Hanoi',
            continentName: 'Asia',
            countryCodeIso3: 'VNM',
        },
        {
            countryCode: 'SB',
            countryName: 'Solomon Islands',
            currencyCode: 'SBD',
            population: '559198',
            capital: 'Honiara',
            continentName: 'Oceania',
            countryCodeIso3: 'SLB',
        },
        {
            countryCode: 'ET',
            countryName: 'Ethiopia',
            currencyCode: 'ETB',
            population: '88013491',
            capital: 'Addis Ababa',
            continentName: 'Africa',
            countryCodeIso3: 'ETH',
        },
        {
            countryCode: 'SO',
            countryName: 'Somalia',
            currencyCode: 'SOS',
            population: '10112453',
            capital: 'Mogadishu',
            continentName: 'Africa',
            countryCodeIso3: 'SOM',
        },
        {
            countryCode: 'ZW',
            countryName: 'Zimbabwe',
            currencyCode: 'ZWL',
            population: '13061000',
            capital: 'Harare',
            continentName: 'Africa',
            countryCodeIso3: 'ZWE',
        },
        {
            countryCode: 'SA',
            countryName: 'Saudi Arabia',
            currencyCode: 'SAR',
            population: '25731776',
            capital: 'Riyadh',
            continentName: 'Asia',
            countryCodeIso3: 'SAU',
        },
        {
            countryCode: 'ES',
            countryName: 'Spain',
            currencyCode: 'EUR',
            population: '46505963',
            capital: 'Madrid',
            continentName: 'Europe',
            countryCodeIso3: 'ESP',
        },
        {
            countryCode: 'ER',
            countryName: 'Eritrea',
            currencyCode: 'ERN',
            population: '5792984',
            capital: 'Asmara',
            continentName: 'Africa',
            countryCodeIso3: 'ERI',
        },
        {
            countryCode: 'ME',
            countryName: 'Montenegro',
            currencyCode: 'EUR',
            population: '666730',
            capital: 'Podgorica',
            continentName: 'Europe',
            countryCodeIso3: 'MNE',
        },
        {
            countryCode: 'MD',
            countryName: 'Moldova',
            currencyCode: 'MDL',
            population: '4324000',
            capital: 'Chişinău',
            continentName: 'Europe',
            countryCodeIso3: 'MDA',
        },
        {
            countryCode: 'MG',
            countryName: 'Madagascar',
            currencyCode: 'MGA',
            population: '21281844',
            capital: 'Antananarivo',
            continentName: 'Africa',
            countryCodeIso3: 'MDG',
        },
        {
            countryCode: 'MF',
            countryName: 'Saint Martin',
            currencyCode: 'EUR',
            population: '35925',
            capital: 'Marigot',
            continentName: 'North America',
            countryCodeIso3: 'MAF',
        },
        {
            countryCode: 'MA',
            countryName: 'Morocco',
            currencyCode: 'MAD',
            population: '33848242',
            capital: 'Rabat',
            continentName: 'Africa',
            countryCodeIso3: 'MAR',
        },
        {
            countryCode: 'MC',
            countryName: 'Monaco',
            currencyCode: 'EUR',
            population: '32965',
            capital: 'Monaco',
            continentName: 'Europe',
            countryCodeIso3: 'MCO',
        },
        {
            countryCode: 'UZ',
            countryName: 'Uzbekistan',
            currencyCode: 'UZS',
            population: '27865738',
            capital: 'Tashkent',
            continentName: 'Asia',
            countryCodeIso3: 'UZB',
        },
        {
            countryCode: 'MM',
            countryName: 'Myanmar [Burma]',
            currencyCode: 'MMK',
            population: '53414374',
            capital: 'Naypyitaw',
            continentName: 'Asia',
            countryCodeIso3: 'MMR',
        },
        {
            countryCode: 'ML',
            countryName: 'Mali',
            currencyCode: 'XOF',
            population: '13796354',
            capital: 'Bamako',
            continentName: 'Africa',
            countryCodeIso3: 'MLI',
        },
        {
            countryCode: 'MO',
            countryName: 'Macao',
            currencyCode: 'MOP',
            population: '449198',
            capital: 'Macao',
            continentName: 'Asia',
            countryCodeIso3: 'MAC',
        },
        {
            countryCode: 'MN',
            countryName: 'Mongolia',
            currencyCode: 'MNT',
            population: '3086918',
            capital: 'Ulan Bator',
            continentName: 'Asia',
            countryCodeIso3: 'MNG',
        },
        {
            countryCode: 'MH',
            countryName: 'Marshall Islands',
            currencyCode: 'USD',
            population: '65859',
            capital: 'Majuro',
            continentName: 'Oceania',
            countryCodeIso3: 'MHL',
        },
        {
            countryCode: 'MK',
            countryName: 'Macedonia',
            currencyCode: 'MKD',
            population: '2062294',
            capital: 'Skopje',
            continentName: 'Europe',
            countryCodeIso3: 'MKD',
        },
        {
            countryCode: 'MU',
            countryName: 'Mauritius',
            currencyCode: 'MUR',
            population: '1294104',
            capital: 'Port Louis',
            continentName: 'Africa',
            countryCodeIso3: 'MUS',
        },
        {
            countryCode: 'MT',
            countryName: 'Malta',
            currencyCode: 'EUR',
            population: '403000',
            capital: 'Valletta',
            continentName: 'Europe',
            countryCodeIso3: 'MLT',
        },
        {
            countryCode: 'MW',
            countryName: 'Malawi',
            currencyCode: 'MWK',
            population: '15447500',
            capital: 'Lilongwe',
            continentName: 'Africa',
            countryCodeIso3: 'MWI',
        },
        {
            countryCode: 'MV',
            countryName: 'Maldives',
            currencyCode: 'MVR',
            population: '395650',
            capital: 'Malé',
            continentName: 'Asia',
            countryCodeIso3: 'MDV',
        },
        {
            countryCode: 'MQ',
            countryName: 'Martinique',
            currencyCode: 'EUR',
            population: '432900',
            capital: 'Fort-de-France',
            continentName: 'North America',
            countryCodeIso3: 'MTQ',
        },
        {
            countryCode: 'MP',
            countryName: 'Northern Mariana Islands',
            currencyCode: 'USD',
            population: '53883',
            capital: 'Saipan',
            continentName: 'Oceania',
            countryCodeIso3: 'MNP',
        },
        {
            countryCode: 'MS',
            countryName: 'Montserrat',
            currencyCode: 'XCD',
            population: '9341',
            capital: 'Plymouth',
            continentName: 'North America',
            countryCodeIso3: 'MSR',
        },
        {
            countryCode: 'MR',
            countryName: 'Mauritania',
            currencyCode: 'MRO',
            population: '3205060',
            capital: 'Nouakchott',
            continentName: 'Africa',
            countryCodeIso3: 'MRT',
        },
        {
            countryCode: 'IM',
            countryName: 'Isle of Man',
            currencyCode: 'GBP',
            population: '75049',
            capital: 'Douglas',
            continentName: 'Europe',
            countryCodeIso3: 'IMN',
        },
        {
            countryCode: 'UG',
            countryName: 'Uganda',
            currencyCode: 'UGX',
            population: '33398682',
            capital: 'Kampala',
            continentName: 'Africa',
            countryCodeIso3: 'UGA',
        },
        {
            countryCode: 'TZ',
            countryName: 'Tanzania',
            currencyCode: 'TZS',
            population: '41892895',
            capital: 'Dodoma',
            continentName: 'Africa',
            countryCodeIso3: 'TZA',
        },
        {
            countryCode: 'MY',
            countryName: 'Malaysia',
            currencyCode: 'MYR',
            population: '28274729',
            capital: 'Kuala Lumpur',
            continentName: 'Asia',
            countryCodeIso3: 'MYS',
        },
        {
            countryCode: 'MX',
            countryName: 'Mexico',
            currencyCode: 'MXN',
            population: '112468855',
            capital: 'Mexico City',
            continentName: 'North America',
            countryCodeIso3: 'MEX',
        },
        {
            countryCode: 'IL',
            countryName: 'Israel',
            currencyCode: 'ILS',
            population: '7353985',
            capital: '',
            continentName: 'Asia',
            countryCodeIso3: 'ISR',
        },
        {
            countryCode: 'FR',
            countryName: 'France',
            currencyCode: 'EUR',
            population: '64768389',
            capital: 'Paris',
            continentName: 'Europe',
            countryCodeIso3: 'FRA',
        },
        {
            countryCode: 'IO',
            countryName: 'British Indian Ocean Territory',
            currencyCode: 'USD',
            population: '4000',
            capital: '',
            continentName: 'Asia',
            countryCodeIso3: 'IOT',
        },
        {
            countryCode: 'SH',
            countryName: 'Saint Helena',
            currencyCode: 'SHP',
            population: '7460',
            capital: 'Jamestown',
            continentName: 'Africa',
            countryCodeIso3: 'SHN',
        },
        {
            countryCode: 'FI',
            countryName: 'Finland',
            currencyCode: 'EUR',
            population: '5244000',
            capital: 'Helsinki',
            continentName: 'Europe',
            countryCodeIso3: 'FIN',
        },
        {
            countryCode: 'FJ',
            countryName: 'Fiji',
            currencyCode: 'FJD',
            population: '875983',
            capital: 'Suva',
            continentName: 'Oceania',
            countryCodeIso3: 'FJI',
        },
        {
            countryCode: 'FK',
            countryName: 'Falkland Islands',
            currencyCode: 'FKP',
            population: '2638',
            capital: 'Stanley',
            continentName: 'South America',
            countryCodeIso3: 'FLK',
        },
        {
            countryCode: 'FM',
            countryName: 'Micronesia',
            currencyCode: 'USD',
            population: '107708',
            capital: 'Palikir',
            continentName: 'Oceania',
            countryCodeIso3: 'FSM',
        },
        {
            countryCode: 'FO',
            countryName: 'Faroe Islands',
            currencyCode: 'DKK',
            population: '48228',
            capital: 'Tórshavn',
            continentName: 'Europe',
            countryCodeIso3: 'FRO',
        },
        {
            countryCode: 'NI',
            countryName: 'Nicaragua',
            currencyCode: 'NIO',
            population: '5995928',
            capital: 'Managua',
            continentName: 'North America',
            countryCodeIso3: 'NIC',
        },
        {
            countryCode: 'NL',
            countryName: 'Netherlands',
            currencyCode: 'EUR',
            population: '16645000',
            capital: 'Amsterdam',
            continentName: 'Europe',
            countryCodeIso3: 'NLD',
        },
        {
            countryCode: 'NO',
            countryName: 'Norway',
            currencyCode: 'NOK',
            population: '5009150',
            capital: 'Oslo',
            continentName: 'Europe',
            countryCodeIso3: 'NOR',
        },
        {
            countryCode: 'NA',
            countryName: 'Namibia',
            currencyCode: 'NAD',
            population: '2128471',
            capital: 'Windhoek',
            continentName: 'Africa',
            countryCodeIso3: 'NAM',
        },
        {
            countryCode: 'VU',
            countryName: 'Vanuatu',
            currencyCode: 'VUV',
            population: '221552',
            capital: 'Port Vila',
            continentName: 'Oceania',
            countryCodeIso3: 'VUT',
        },
        {
            countryCode: 'NC',
            countryName: 'New Caledonia',
            currencyCode: 'XPF',
            population: '216494',
            capital: 'Noumea',
            continentName: 'Oceania',
            countryCodeIso3: 'NCL',
        },
        {
            countryCode: 'NE',
            countryName: 'Niger',
            currencyCode: 'XOF',
            population: '15878271',
            capital: 'Niamey',
            continentName: 'Africa',
            countryCodeIso3: 'NER',
        },
        {
            countryCode: 'NF',
            countryName: 'Norfolk Island',
            currencyCode: 'AUD',
            population: '1828',
            capital: 'Kingston',
            continentName: 'Oceania',
            countryCodeIso3: 'NFK',
        },
        {
            countryCode: 'NG',
            countryName: 'Nigeria',
            currencyCode: 'NGN',
            population: '154000000',
            capital: 'Abuja',
            continentName: 'Africa',
            countryCodeIso3: 'NGA',
        },
        {
            countryCode: 'NZ',
            countryName: 'New Zealand',
            currencyCode: 'NZD',
            population: '4252277',
            capital: 'Wellington',
            continentName: 'Oceania',
            countryCodeIso3: 'NZL',
        },
        {
            countryCode: 'NP',
            countryName: 'Nepal',
            currencyCode: 'NPR',
            population: '28951852',
            capital: 'Kathmandu',
            continentName: 'Asia',
            countryCodeIso3: 'NPL',
        },
        {
            countryCode: 'NR',
            countryName: 'Nauru',
            currencyCode: 'AUD',
            population: '10065',
            capital: 'Yaren',
            continentName: 'Oceania',
            countryCodeIso3: 'NRU',
        },
        {
            countryCode: 'NU',
            countryName: 'Niue',
            currencyCode: 'NZD',
            population: '2166',
            capital: 'Alofi',
            continentName: 'Oceania',
            countryCodeIso3: 'NIU',
        },
        {
            countryCode: 'CK',
            countryName: 'Cook Islands',
            currencyCode: 'NZD',
            population: '21388',
            capital: 'Avarua',
            continentName: 'Oceania',
            countryCodeIso3: 'COK',
        },
        {
            countryCode: 'XK',
            countryName: 'Kosovo',
            currencyCode: 'EUR',
            population: '1800000',
            capital: 'Pristina',
            continentName: 'Europe',
            countryCodeIso3: 'XKX',
        },
        {
            countryCode: 'CI',
            countryName: 'Ivory Coast',
            currencyCode: 'XOF',
            population: '21058798',
            capital: 'Yamoussoukro',
            continentName: 'Africa',
            countryCodeIso3: 'CIV',
        },
        {
            countryCode: 'CH',
            countryName: 'Switzerland',
            currencyCode: 'CHF',
            population: '7581000',
            capital: 'Bern',
            continentName: 'Europe',
            countryCodeIso3: 'CHE',
        },
        {
            countryCode: 'CO',
            countryName: 'Colombia',
            currencyCode: 'COP',
            population: '47790000',
            capital: 'Bogotá',
            continentName: 'South America',
            countryCodeIso3: 'COL',
        },
        {
            countryCode: 'CN',
            countryName: 'China',
            currencyCode: 'CNY',
            population: '1330044000',
            capital: 'Beijing',
            continentName: 'Asia',
            countryCodeIso3: 'CHN',
        },
        {
            countryCode: 'CM',
            countryName: 'Cameroon',
            currencyCode: 'XAF',
            population: '19294149',
            capital: 'Yaoundé',
            continentName: 'Africa',
            countryCodeIso3: 'CMR',
        },
        {
            countryCode: 'CL',
            countryName: 'Chile',
            currencyCode: 'CLP',
            population: '16746491',
            capital: 'Santiago',
            continentName: 'South America',
            countryCodeIso3: 'CHL',
        },
        {
            countryCode: 'CC',
            countryName: 'Cocos [Keeling] Islands',
            currencyCode: 'AUD',
            population: '628',
            capital: 'West Island',
            continentName: 'Asia',
            countryCodeIso3: 'CCK',
        },
        {
            countryCode: 'CA',
            countryName: 'Canada',
            currencyCode: 'CAD',
            population: '33679000',
            capital: 'Ottawa',
            continentName: 'North America',
            countryCodeIso3: 'CAN',
        },
        {
            countryCode: 'CG',
            countryName: 'Republic of the Congo',
            currencyCode: 'XAF',
            population: '3039126',
            capital: 'Brazzaville',
            continentName: 'Africa',
            countryCodeIso3: 'COG',
        },
        {
            countryCode: 'CF',
            countryName: 'Central African Republic',
            currencyCode: 'XAF',
            population: '4844927',
            capital: 'Bangui',
            continentName: 'Africa',
            countryCodeIso3: 'CAF',
        },
        {
            countryCode: 'CD',
            countryName: 'Democratic Republic of the Congo',
            currencyCode: 'CDF',
            population: '70916439',
            capital: 'Kinshasa',
            continentName: 'Africa',
            countryCodeIso3: 'COD',
        },
        {
            countryCode: 'CZ',
            countryName: 'Czechia',
            currencyCode: 'CZK',
            population: '10476000',
            capital: 'Prague',
            continentName: 'Europe',
            countryCodeIso3: 'CZE',
        },
        {
            countryCode: 'CY',
            countryName: 'Cyprus',
            currencyCode: 'EUR',
            population: '1102677',
            capital: 'Nicosia',
            continentName: 'Europe',
            countryCodeIso3: 'CYP',
        },
        {
            countryCode: 'CX',
            countryName: 'Christmas Island',
            currencyCode: 'AUD',
            population: '1500',
            capital: 'Flying Fish Cove',
            continentName: 'Asia',
            countryCodeIso3: 'CXR',
        },
        {
            countryCode: 'CR',
            countryName: 'Costa Rica',
            currencyCode: 'CRC',
            population: '4516220',
            capital: 'San José',
            continentName: 'North America',
            countryCodeIso3: 'CRI',
        },
        {
            countryCode: 'CW',
            countryName: 'Curacao',
            currencyCode: 'ANG',
            population: '141766',
            capital: 'Willemstad',
            continentName: 'North America',
            countryCodeIso3: 'CUW',
        },
        {
            countryCode: 'CV',
            countryName: 'Cape Verde',
            currencyCode: 'CVE',
            population: '508659',
            capital: 'Praia',
            continentName: 'Africa',
            countryCodeIso3: 'CPV',
        },
        {
            countryCode: 'CU',
            countryName: 'Cuba',
            currencyCode: 'CUP',
            population: '11423000',
            capital: 'Havana',
            continentName: 'North America',
            countryCodeIso3: 'CUB',
        },
        {
            countryCode: 'SZ',
            countryName: 'Swaziland',
            currencyCode: 'SZL',
            population: '1354051',
            capital: 'Mbabane',
            continentName: 'Africa',
            countryCodeIso3: 'SWZ',
        },
        {
            countryCode: 'SY',
            countryName: 'Syria',
            currencyCode: 'SYP',
            population: '22198110',
            capital: 'Damascus',
            continentName: 'Asia',
            countryCodeIso3: 'SYR',
        },
        {
            countryCode: 'SX',
            countryName: 'Sint Maarten',
            currencyCode: 'ANG',
            population: '37429',
            capital: 'Philipsburg',
            continentName: 'North America',
            countryCodeIso3: 'SXM',
        },
        {
            countryCode: 'KG',
            countryName: 'Kyrgyzstan',
            currencyCode: 'KGS',
            population: '5776500',
            capital: 'Bishkek',
            continentName: 'Asia',
            countryCodeIso3: 'KGZ',
        },
        {
            countryCode: 'KE',
            countryName: 'Kenya',
            currencyCode: 'KES',
            population: '40046566',
            capital: 'Nairobi',
            continentName: 'Africa',
            countryCodeIso3: 'KEN',
        },
        {
            countryCode: 'SS',
            countryName: 'South Sudan',
            currencyCode: 'SSP',
            population: '8260490',
            capital: 'Juba',
            continentName: 'Africa',
            countryCodeIso3: 'SSD',
        },
        {
            countryCode: 'SR',
            countryName: 'Suriname',
            currencyCode: 'SRD',
            population: '492829',
            capital: 'Paramaribo',
            continentName: 'South America',
            countryCodeIso3: 'SUR',
        },
        {
            countryCode: 'KI',
            countryName: 'Kiribati',
            currencyCode: 'AUD',
            population: '92533',
            capital: 'Tarawa',
            continentName: 'Oceania',
            countryCodeIso3: 'KIR',
        },
        {
            countryCode: 'KH',
            countryName: 'Cambodia',
            currencyCode: 'KHR',
            population: '14453680',
            capital: 'Phnom Penh',
            continentName: 'Asia',
            countryCodeIso3: 'KHM',
        },
        {
            countryCode: 'KN',
            countryName: 'Saint Kitts and Nevis',
            currencyCode: 'XCD',
            population: '51134',
            capital: 'Basseterre',
            continentName: 'North America',
            countryCodeIso3: 'KNA',
        },
        {
            countryCode: 'KM',
            countryName: 'Comoros',
            currencyCode: 'KMF',
            population: '773407',
            capital: 'Moroni',
            continentName: 'Africa',
            countryCodeIso3: 'COM',
        },
        {
            countryCode: 'ST',
            countryName: 'São Tomé and Príncipe',
            currencyCode: 'STD',
            population: '175808',
            capital: 'São Tomé',
            continentName: 'Africa',
            countryCodeIso3: 'STP',
        },
        {
            countryCode: 'SK',
            countryName: 'Slovakia',
            currencyCode: 'EUR',
            population: '5455000',
            capital: 'Bratislava',
            continentName: 'Europe',
            countryCodeIso3: 'SVK',
        },
        {
            countryCode: 'KR',
            countryName: 'South Korea',
            currencyCode: 'KRW',
            population: '48422644',
            capital: 'Seoul',
            continentName: 'Asia',
            countryCodeIso3: 'KOR',
        },
        {
            countryCode: 'SI',
            countryName: 'Slovenia',
            currencyCode: 'EUR',
            population: '2007000',
            capital: 'Ljubljana',
            continentName: 'Europe',
            countryCodeIso3: 'SVN',
        },
        {
            countryCode: 'KP',
            countryName: 'North Korea',
            currencyCode: 'KPW',
            population: '22912177',
            capital: 'Pyongyang',
            continentName: 'Asia',
            countryCodeIso3: 'PRK',
        },
        {
            countryCode: 'KW',
            countryName: 'Kuwait',
            currencyCode: 'KWD',
            population: '2789132',
            capital: 'Kuwait City',
            continentName: 'Asia',
            countryCodeIso3: 'KWT',
        },
        {
            countryCode: 'SN',
            countryName: 'Senegal',
            currencyCode: 'XOF',
            population: '12323252',
            capital: 'Dakar',
            continentName: 'Africa',
            countryCodeIso3: 'SEN',
        },
        {
            countryCode: 'SM',
            countryName: 'San Marino',
            currencyCode: 'EUR',
            population: '31477',
            capital: 'San Marino',
            continentName: 'Europe',
            countryCodeIso3: 'SMR',
        },
        {
            countryCode: 'SL',
            countryName: 'Sierra Leone',
            currencyCode: 'SLL',
            population: '5245695',
            capital: 'Freetown',
            continentName: 'Africa',
            countryCodeIso3: 'SLE',
        },
        {
            countryCode: 'SC',
            countryName: 'Seychelles',
            currencyCode: 'SCR',
            population: '88340',
            capital: 'Victoria',
            continentName: 'Africa',
            countryCodeIso3: 'SYC',
        },
        {
            countryCode: 'KZ',
            countryName: 'Kazakhstan',
            currencyCode: 'KZT',
            population: '15340000',
            capital: 'Astana',
            continentName: 'Asia',
            countryCodeIso3: 'KAZ',
        },
        {
            countryCode: 'KY',
            countryName: 'Cayman Islands',
            currencyCode: 'KYD',
            population: '44270',
            capital: 'George Town',
            continentName: 'North America',
            countryCodeIso3: 'CYM',
        },
        {
            countryCode: 'SG',
            countryName: 'Singapore',
            currencyCode: 'SGD',
            population: '4701069',
            capital: 'Singapore',
            continentName: 'Asia',
            countryCodeIso3: 'SGP',
        },
        {
            countryCode: 'SE',
            countryName: 'Sweden',
            currencyCode: 'SEK',
            population: '9828655',
            capital: 'Stockholm',
            continentName: 'Europe',
            countryCodeIso3: 'SWE',
        },
        {
            countryCode: 'SD',
            countryName: 'Sudan',
            currencyCode: 'SDG',
            population: '35000000',
            capital: 'Khartoum',
            continentName: 'Africa',
            countryCodeIso3: 'SDN',
        },
        {
            countryCode: 'DO',
            countryName: 'Dominican Republic',
            currencyCode: 'DOP',
            population: '9823821',
            capital: 'Santo Domingo',
            continentName: 'North America',
            countryCodeIso3: 'DOM',
        },
        {
            countryCode: 'DM',
            countryName: 'Dominica',
            currencyCode: 'XCD',
            population: '72813',
            capital: 'Roseau',
            continentName: 'North America',
            countryCodeIso3: 'DMA',
        },
        {
            countryCode: 'DJ',
            countryName: 'Djibouti',
            currencyCode: 'DJF',
            population: '740528',
            capital: 'Djibouti',
            continentName: 'Africa',
            countryCodeIso3: 'DJI',
        },
        {
            countryCode: 'DK',
            countryName: 'Denmark',
            currencyCode: 'DKK',
            population: '5484000',
            capital: 'Copenhagen',
            continentName: 'Europe',
            countryCodeIso3: 'DNK',
        },
        {
            countryCode: 'VG',
            countryName: 'British Virgin Islands',
            currencyCode: 'USD',
            population: '21730',
            capital: 'Road Town',
            continentName: 'North America',
            countryCodeIso3: 'VGB',
        },
        {
            countryCode: 'DE',
            countryName: 'Germany',
            currencyCode: 'EUR',
            population: '81802257',
            capital: 'Berlin',
            continentName: 'Europe',
            countryCodeIso3: 'DEU',
        },
        {
            countryCode: 'YE',
            countryName: 'Yemen',
            currencyCode: 'YER',
            population: '23495361',
            capital: 'Sanaa',
            continentName: 'Asia',
            countryCodeIso3: 'YEM',
        },
        {
            countryCode: 'DZ',
            countryName: 'Algeria',
            currencyCode: 'DZD',
            population: '34586184',
            capital: 'Algiers',
            continentName: 'Africa',
            countryCodeIso3: 'DZA',
        },
        {
            countryCode: 'US',
            countryName: 'United States',
            currencyCode: 'USD',
            population: '310232863',
            capital: 'Washington',
            continentName: 'North America',
            countryCodeIso3: 'USA',
        },
        {
            countryCode: 'UY',
            countryName: 'Uruguay',
            currencyCode: 'UYU',
            population: '3477000',
            capital: 'Montevideo',
            continentName: 'South America',
            countryCodeIso3: 'URY',
        },
        {
            countryCode: 'YT',
            countryName: 'Mayotte',
            currencyCode: 'EUR',
            population: '159042',
            capital: 'Mamoudzou',
            continentName: 'Africa',
            countryCodeIso3: 'MYT',
        },
        {
            countryCode: 'UM',
            countryName: 'U.S. Minor Outlying Islands',
            currencyCode: 'USD',
            population: '0',
            capital: '',
            continentName: 'Oceania',
            countryCodeIso3: 'UMI',
        },
        {
            countryCode: 'LB',
            countryName: 'Lebanon',
            currencyCode: 'LBP',
            population: '4125247',
            capital: 'Beirut',
            continentName: 'Asia',
            countryCodeIso3: 'LBN',
        },
        {
            countryCode: 'LC',
            countryName: 'Saint Lucia',
            currencyCode: 'XCD',
            population: '160922',
            capital: 'Castries',
            continentName: 'North America',
            countryCodeIso3: 'LCA',
        },
        {
            countryCode: 'LA',
            countryName: 'Laos',
            currencyCode: 'LAK',
            population: '6368162',
            capital: 'Vientiane',
            continentName: 'Asia',
            countryCodeIso3: 'LAO',
        },
        {
            countryCode: 'TV',
            countryName: 'Tuvalu',
            currencyCode: 'AUD',
            population: '10472',
            capital: 'Funafuti',
            continentName: 'Oceania',
            countryCodeIso3: 'TUV',
        },
        {
            countryCode: 'TW',
            countryName: 'Taiwan',
            currencyCode: 'TWD',
            population: '22894384',
            capital: 'Taipei',
            continentName: 'Asia',
            countryCodeIso3: 'TWN',
        },
        {
            countryCode: 'TT',
            countryName: 'Trinidad and Tobago',
            currencyCode: 'TTD',
            population: '1228691',
            capital: 'Port of Spain',
            continentName: 'North America',
            countryCodeIso3: 'TTO',
        },
        {
            countryCode: 'TR',
            countryName: 'Turkey',
            currencyCode: 'TRY',
            population: '77804122',
            capital: 'Ankara',
            continentName: 'Asia',
            countryCodeIso3: 'TUR',
        },
        {
            countryCode: 'LK',
            countryName: 'Sri Lanka',
            currencyCode: 'LKR',
            population: '21513990',
            capital: 'Colombo',
            continentName: 'Asia',
            countryCodeIso3: 'LKA',
        },
        {
            countryCode: 'LI',
            countryName: 'Liechtenstein',
            currencyCode: 'CHF',
            population: '35000',
            capital: 'Vaduz',
            continentName: 'Europe',
            countryCodeIso3: 'LIE',
        },
        {
            countryCode: 'LV',
            countryName: 'Latvia',
            currencyCode: 'EUR',
            population: '2217969',
            capital: 'Riga',
            continentName: 'Europe',
            countryCodeIso3: 'LVA',
        },
        {
            countryCode: 'TO',
            countryName: 'Tonga',
            currencyCode: 'TOP',
            population: '122580',
            capital: 'Nuku\'alofa',
            continentName: 'Oceania',
            countryCodeIso3: 'TON',
        },
        {
            countryCode: 'LT',
            countryName: 'Lithuania',
            currencyCode: 'EUR',
            population: '2944459',
            capital: 'Vilnius',
            continentName: 'Europe',
            countryCodeIso3: 'LTU',
        },
        {
            countryCode: 'LU',
            countryName: 'Luxembourg',
            currencyCode: 'EUR',
            population: '497538',
            capital: 'Luxembourg',
            continentName: 'Europe',
            countryCodeIso3: 'LUX',
        },
        {
            countryCode: 'LR',
            countryName: 'Liberia',
            currencyCode: 'LRD',
            population: '3685076',
            capital: 'Monrovia',
            continentName: 'Africa',
            countryCodeIso3: 'LBR',
        },
        {
            countryCode: 'LS',
            countryName: 'Lesotho',
            currencyCode: 'LSL',
            population: '1919552',
            capital: 'Maseru',
            continentName: 'Africa',
            countryCodeIso3: 'LSO',
        },
        {
            countryCode: 'TH',
            countryName: 'Thailand',
            currencyCode: 'THB',
            population: '67089500',
            capital: 'Bangkok',
            continentName: 'Asia',
            countryCodeIso3: 'THA',
        },
        {
            countryCode: 'TF',
            countryName: 'French Southern Territories',
            currencyCode: 'EUR',
            population: '140',
            capital: 'Port-aux-Français',
            continentName: 'Antarctica',
            countryCodeIso3: 'ATF',
        },
        {
            countryCode: 'TG',
            countryName: 'Togo',
            currencyCode: 'XOF',
            population: '6587239',
            capital: 'Lomé',
            continentName: 'Africa',
            countryCodeIso3: 'TGO',
        },
        {
            countryCode: 'TD',
            countryName: 'Chad',
            currencyCode: 'XAF',
            population: '10543464',
            capital: 'N\'Djamena',
            continentName: 'Africa',
            countryCodeIso3: 'TCD',
        },
        {
            countryCode: 'TC',
            countryName: 'Turks and Caicos Islands',
            currencyCode: 'USD',
            population: '20556',
            capital: 'Cockburn Town',
            continentName: 'North America',
            countryCodeIso3: 'TCA',
        },
        {
            countryCode: 'LY',
            countryName: 'Libya',
            currencyCode: 'LYD',
            population: '6461454',
            capital: 'Tripoli',
            continentName: 'Africa',
            countryCodeIso3: 'LBY',
        },
        {
            countryCode: 'VA',
            countryName: 'Vatican City',
            currencyCode: 'EUR',
            population: '921',
            capital: 'Vatican City',
            continentName: 'Europe',
            countryCodeIso3: 'VAT',
        },
        {
            countryCode: 'VC',
            countryName: 'Saint Vincent and the Grenadines',
            currencyCode: 'XCD',
            population: '104217',
            capital: 'Kingstown',
            continentName: 'North America',
            countryCodeIso3: 'VCT',
        },
        {
            countryCode: 'AE',
            countryCodeIso3: 'ARE',
            countryName: 'United Arab Emirates',
            currencyCode: 'AED',
            population: '4975593',
            capital: 'Abu Dhabi',
            continentName: 'Asia',
        },
        {
            countryCode: 'AD',
            countryCodeIso3: 'AND',
            countryName: 'Andorra',
            currencyCode: 'EUR',
            population: '84000',
            capital: 'Andorra la Vella',
            continentName: 'Europe',
        },
        {
            countryCode: 'AG',
            countryCodeIso3: 'ATG',
            countryName: 'Antigua and Barbuda',
            currencyCode: 'XCD',
            population: '86754',
            capital: 'St. John\'s',
            continentName: 'North America',
        },
        {
            countryCode: 'AF',
            countryCodeIso3: 'AFG',
            countryName: 'Afghanistan',
            currencyCode: 'AFN',
            population: '29121286',
            capital: 'Kabul',
            continentName: 'Asia',
        },
        {
            countryCode: 'AI',
            countryCodeIso3: 'AIA',
            countryName: 'Anguilla',
            currencyCode: 'XCD',
            population: '13254',
            capital: 'The Valley',
            continentName: 'North America',
        },
        {
            countryCode: 'VI',
            countryName: 'U.S. Virgin Islands',
            currencyCode: 'USD',
            population: '108708',
            capital: 'Charlotte Amalie',
            continentName: 'North America',
            countryCodeIso3: 'VIR',
        },
        {
            countryCode: 'IS',
            countryName: 'Iceland',
            currencyCode: 'ISK',
            population: '308910',
            capital: 'Reykjavik',
            continentName: 'Europe',
            countryCodeIso3: 'ISL',
        },
        {
            countryCode: 'IR',
            countryName: 'Iran',
            currencyCode: 'IRR',
            population: '76923300',
            capital: 'Tehran',
            continentName: 'Asia',
            countryCodeIso3: 'IRN',
        },
        {
            countryCode: 'AM',
            countryCodeIso3: 'ARM',
            countryName: 'Armenia',
            currencyCode: 'AMD',
            population: '2968000',
            capital: 'Yerevan',
            continentName: 'Asia',
        },
        {
            countryCode: 'AL',
            countryCodeIso3: 'ALB',
            countryName: 'Albania',
            currencyCode: 'ALL',
            population: '2986952',
            capital: 'Tirana',
            continentName: 'Europe',
        },
        {
            countryCode: 'AO',
            countryCodeIso3: 'AGO',
            countryName: 'Angola',
            currencyCode: 'AOA',
            population: '13068161',
            capital: 'Luanda',
            continentName: 'Africa',
        },
        {
            countryCode: 'AQ',
            countryCodeIso3: 'ATA',
            countryName: 'Antarctica',
            currencyCode: '',
            population: '0',
            capital: '',
            continentName: 'Antarctica',
        },
        {
            countryCode: 'AS',
            countryCodeIso3: 'ASM',
            countryName: 'American Samoa',
            currencyCode: 'USD',
            population: '57881',
            capital: 'Pago Pago',
            continentName: 'Oceania',
        },
        {
            countryCode: 'AR',
            countryCodeIso3: 'ARG',
            countryName: 'Argentina',
            currencyCode: 'ARS',
            population: '41343201',
            capital: 'Buenos Aires',
            continentName: 'South America',
        },
        {
            countryCode: 'AU',
            countryCodeIso3: 'AUS',
            countryName: 'Australia',
            currencyCode: 'AUD',
            population: '21515754',
            capital: 'Canberra',
            continentName: 'Oceania',
        },
        {
            countryCode: 'AT',
            countryCodeIso3: 'AUT',
            countryName: 'Austria',
            currencyCode: 'EUR',
            population: '8205000',
            capital: 'Vienna',
            continentName: 'Europe',
        },
        {
            countryCode: 'AW',
            countryCodeIso3: 'ABW',
            countryName: 'Aruba',
            currencyCode: 'AWG',
            population: '71566',
            capital: 'Oranjestad',
            continentName: 'North America',
        },
        {
            countryCode: 'IN',
            countryName: 'India',
            currencyCode: 'INR',
            population: '1173108018',
            capital: 'New Delhi',
            continentName: 'Asia',
            countryCodeIso3: 'IND',
        },
        {
            countryCode: 'AX',
            countryName: 'Åland',
            currencyCode: 'EUR',
            population: '26711',
            capital: 'Mariehamn',
            continentName: 'Europe',
            countryCodeIso3: 'ALA',
        },
        {
            countryCode: 'AZ',
            countryName: 'Azerbaijan',
            currencyCode: 'AZN',
            population: '8303512',
            capital: 'Baku',
            continentName: 'Asia',
            countryCodeIso3: 'AZE',
        },
        {
            countryCode: 'IE',
            countryName: 'Ireland',
            currencyCode: 'EUR',
            population: '4622917',
            capital: 'Dublin',
            continentName: 'Europe',
            countryCodeIso3: 'IRL',
        },
        {
            countryCode: 'ID',
            countryName: 'Indonesia',
            currencyCode: 'IDR',
            population: '242968342',
            capital: 'Jakarta',
            continentName: 'Asia',
            countryCodeIso3: 'IDN',
        },
        {
            countryCode: 'UA',
            countryName: 'Ukraine',
            currencyCode: 'UAH',
            population: '45415596',
            capital: 'Kiev',
            continentName: 'Europe',
            countryCodeIso3: 'UKR',
        },
        {
            countryCode: 'QA',
            countryName: 'Qatar',
            currencyCode: 'QAR',
            population: '840926',
            capital: 'Doha',
            continentName: 'Asia',
            countryCodeIso3: 'QAT',
        },
        {
            countryCode: 'MZ',
            countryName: 'Mozambique',
            currencyCode: 'MZN',
            population: '22061451',
            capital: 'Maputo',
            continentName: 'Africa',
            countryCodeIso3: 'MOZ',
        },
    ],
};
